import { useTranslation } from "react-i18next";
import { footerElements, footerInformation, footerRrss } from "./content/footer"

import AppleStore from "./../../assets/store/app-store.svg";
import GooglePlay from "./../../assets/store/google-play.svg";
import KincodeLogo from "./../../assets/logo/kincode-logo.svg";
import { Link } from "react-router-dom";
import Cta from "../cta/Cta";


export default function Footer() {
    const { t } = useTranslation();

    return(
        <div className="flex flex-col bg-[#F1F2F5] py-10">
            <div className="w-full max-w-screen-xl p-5 flex flex-row flex-wrap gap-5 justify-start sm:justify-between m-auto text-left">
                {footerElements?.map((section) => {
                    return(
                        <div className="flex flex-col px-5 w-full sm:w-[45%] lg:w-fit max-w-[295x] border-l-2 border-slate-300">
                            <h3 className="font-bold">{t(section.title)}</h3>
                            <ul className="flex flex-col">
                                {section.elements.map((element) => {
                                    return(
                                        <li>{element}</li>
                                    )
                                })}

                            </ul>
                        </div>
                    )
                })}
            </div>
            <div className="w-full max-w-screen-xl p-5 flex flex-row flex-wrap gap-5 justify-center items-center m-auto" >
                <img className="max-h-10 h-10 w-auto" src={KincodeLogo} alt="Kincode logo" />

                {/* <div className="flex flex-row flex-wrap gap-5 justify-center overflow-hidden">
                    <Link className="h-12 flex justify-center items-center shrink-0" to="#">
                        <img className="h-40" src={AppleStore} alt="Applestore logo" />
                    </Link>
                    
                    <Link className="h-12 flex justify-center items-center shrink-0" to="#">
                        <img className="h-40" src={GooglePlay} alt="Google Play logo" />
                    </Link>
                </div> */}

            </div>
            <div className="w-full max-w-screen-xl h-full p-5 m-auto flex flex-row flex-wrap justify-center gap-5">
                {footerInformation.map((element) => {
                    return(
                        <div className="pr-5 border-r border-slate-300">
                            <Cta text={element.text} btnType="tertiary-black" goTo={element.goTo} />
                        </div>
                    )
                })}
                <div className="flex flex-row">
                    {footerRrss.map((element) => {
                        return(
                            <Link className="h-12 w-12 flex justify-center items-center" to={element.url} target="_blank">
                                {element.icon}
                            </Link>
                        )
                    })}
                </div>
            </div>
            <div className="w-fit m-auto">
                <p>{t("footer.information.copy")}</p>
            </div>
        </div>
    )
}