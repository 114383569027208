import { useLayoutEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Suspense } from "react";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Loader from "./../../components/lottie/Lottie";

export default function Layout() {
    let location = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo({top:0, behavior: "instant"});
    }, [location.pathname]);

    return(
        <>
            <Navbar />
            <Breadcrumbs />

            <Suspense fallback={<Loader/>}>
                <Outlet />
            </Suspense>
            
            <Footer />
        </>
        
        
    )
}