import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";

/**
 * Primary, secondary and tertiary button with left and right icon
 * 
 * @param {string} text Button text
 * @param {string} btnType Button type: 'primary' | 'secondary' | 'tertiary' | 'tertiary-black'
 * @param {string} click Button function on click
 * @param {string} goTo Internal page route
 * @param {component} iconLeft React icon
 * @param {component} iconRight React icon
 * @param {boolean} rotateAnimation Right icon animation by default false
 * @param {boolean} isSelected Selected state by default false
 * 
 * @returns 
 */
export default function Cta({text="Btn text", btnType='primary', click=()=>{}, iconLeft, iconRight, goTo="routes.working", rotateAnimation=false, isSelected=false}) {
    let btnStyles = "";
    switch (btnType) {
        case "primary":
            btnStyles = "h-12 px-6 bg-[#FFBF76] hover:bg-[#F79737] rounded font-bold shadow-lg flex flex-row justify-center gap-x-2 items-center cursor-pointer"
            
            break;

        case "secondary":
            btnStyles = "h-12 px-6 border-solid border-2 border-[#FFBF76] hover:border-[#F79737] rounded font-bold text-[#994F00] shadow-lg flex flex-row justify-center gap-x-2 items-center hover:cursor-pointer"
            
            break;

        case "tertiary":
            btnStyles = "h-12 w-fit font-bold text-[#107D35] text-left hover:underline flex flex-row gap-x-2 items-center hover:cursor-pointer"
            
            break;
        
        case "tertiary-black":
            btnStyles = "h-12 w-fit text-black text-left hover:text-[#107D35] hover:underline flex flex-row gap-x-2 items-center hover:cursor-pointer"
            
            break;
    
        default:
            break;
    }

    const { t } = useTranslation();
    
    const [isClicked, setIsClicked] = useState(false);

    const [rightIconStyles, setRightIconStyles] = useState("w-4 h-4");

    useEffect(() => {
        
        if (rotateAnimation && isClicked===true) {
            setRightIconStyles("w-4 h-4 transition-transform -rotate-180");
        } else {
            setRightIconStyles("w-4 h-4 transition-transform rotate-0");
        }
        
    }, [isClicked, rotateAnimation]);

    let location = useLocation();

    useEffect(() => {
        setRightIconStyles("w-4 h-4 transition-transform rotate-0");
        setIsClicked(false);
    }, [location.pathname]);
    
    const [navSelectedStyles, setNavSelectedStyles] = useState(undefined);
    useEffect(() => {
        setIsClicked(isSelected);
        if(btnType === "nav") {
            if( isSelected===true) {
                setNavSelectedStyles("h-12 w-fit text-black text-left border-b-4 -mb-2 border-[#BBF7D0] flex flex-row gap-x-2 items-center");
            } else {
                setNavSelectedStyles("h-12 w-fit text-black text-left border-b-4 border-black/0 -mb-2 hover:border-[#BBF7D0] flex flex-row gap-x-2 items-center");
            }
        }

    }, [isSelected, btnType])


    return(
        <NavLink to={ t(goTo) }
            onClick={() => {
                setIsClicked(!isClicked);
                click();
            }} 
            className={btnType === "nav" ? navSelectedStyles : btnStyles}
        >
            {iconLeft &&
                <div className="w-4 h-4">{iconLeft}</div>
            }

            {t(text)}

            {iconRight &&
                <div className={rightIconStyles}>{iconRight}</div>
            }
        </NavLink>

    )
}