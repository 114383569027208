import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LuChevronRightCircle, LuChevronLeftCircle  } from "react-icons/lu";

// import Cta from "../../components/cta/Cta";

/**
 * Carousel with person slides
 * 
 * @param {array[]} slides Array with person information
 * @returns 
 */
export default function Carousel({ slides }) {    
    let [current, setCurrent] = useState(0);

    let previousSlide = () => {
        if (current === 0) {
            setCurrent(slides.length - 1);

        } else setCurrent(current - 1);
    };

    let nextSlide = () => {
        if (current === slides.length - 1) setCurrent(0);
        else setCurrent(current + 1);
    };

    return (
        <div className="overflow-hidden relative max-w-screen-2xl m-auto">
            <div
            className={`flex transition ease-out duration-40`}
            style={{
                transform: `translateX(-${current * 100}%)`,
            }}
            >
                {slides?.map((slide) => {
                    return(
                        <div className="w-full shrink-0">
                            <PersonSlide person={slide} />
                        </div>

                    );
                })}
            </div>

            <div className="absolute top-0 h-full w-full justify-between items-center flex text-[#757575] px-10 text-4xl invisible lg:visible">
                <button onClick={previousSlide} aria-label="Left arrow" >
                    <LuChevronLeftCircle />
                </button>

                <button onClick={nextSlide} aria-label="Right arrow">
                    <LuChevronRightCircle />
                </button>
            </div>

            <div className="absolute bottom-0 py-4 flex justify-center gap-3 w-full">
                {slides?.map((slide, i) => {
                    return (
                        <div
                            onClick={() => {
                            setCurrent(i);
                            }}
                            key={"circle" + i}
                            className={`rounded-full w-16 h-1 cursor-pointer  ${
                            i == current ? "bg-white" : "bg-[#757575]"
                            }`}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
}

/**
 * Person information slide
 * 
 * @param {object{}} person {personImg, imgAlt, text, name, position, logo}
 * @returns 
 */
export function PersonSlide({person}) {
    const { t, i18n } = useTranslation();

    return(
        <div className="w-full px-5 py-16 flex flex-col md:flex-row justify-center items-center gap-8 md:gap-32  max-w-screen-xl m-auto">
            <img className="w-56 h-56 rounded-full" src={person.personImg} alt={person.imgAlt} />

            <div className="flex flex-col items-start">
                <p className="italic text-left max-w-[75ch] mb-4">
                    {t(person.text)}
                </p>
                <div className="w-full flex flex-row gap-5 flex-wrap justify-between items-center mb-8">
                    <div className="text-left">
                        <p className="font-bold">
                            {t(person.name)}
                        </p>
                        <p>
                            {t(person.position)}
                        </p>
                    </div>

                    <img className="w-40 h-auto grayscale" src={person.logo} alt="" />
                </div>
                {/* <Cta text="Leer más opiniones" btnType="primary"/> */}
            </div>
        </div>
    )
}