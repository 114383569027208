
// Dev environment
const devEnvironment = (process.env.NODE_ENV === undefined || process.env.NODE_ENV === "development" ?
true : false);

// Backend configs
const backendProdURL = "https://backend.kincode.app"
const backendDevURL = "http://localhost:3001"

let amplitudeAPIKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

// ------------------------------------------------------------------------------------------
// API calls
let companyInfoAPI = (devEnvironment ? backendDevURL : backendProdURL) + "/api/post/submitCompanyInfo/";
let newValuesRequestAPI = (devEnvironment ? backendDevURL : backendProdURL) + "/api/post/newValuesRequest/";
let getHRAssessmentAPI  = (devEnvironment ? backendDevURL : backendProdURL) + "/api/get/HRAssessment/";
let dashboardAPI = (devEnvironment ? backendDevURL : backendProdURL) + '/api/get/dashboard/';
let loginAPI = (devEnvironment ? backendDevURL : backendProdURL) + "/api/post/login/";
let getUniversalValuesAPI = (devEnvironment ? backendDevURL : backendProdURL) + "/api/get/universalValues/";
let pulseRequirementsAPI = (devEnvironment ? backendDevURL : backendProdURL) + "/api/post/employeePulseRequirements/";
let confirmAppointmentAPI = (devEnvironment ? backendDevURL : backendProdURL) + "/api/post/confirmAppointment/";
let subscribeAPI = (devEnvironment ? backendDevURL : backendProdURL) + '/api/post/subscribe/';


let openAIAPI = (devEnvironment ? backendDevURL : backendProdURL) + "/api/get/chat/";




module.exports = {
    devEnvironment,

    backendProdURL, backendDevURL,

    amplitudeAPIKey,

    companyInfoAPI, newValuesRequestAPI, getHRAssessmentAPI, dashboardAPI, loginAPI, getUniversalValuesAPI, pulseRequirementsAPI,
    confirmAppointmentAPI, subscribeAPI,

    openAIAPI
}