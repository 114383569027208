import './App.css';

// React 
import React, { useEffect } from "react";
import {BrowserRouter} from 'react-router-dom';

// 3P
// import ReactGA from 'react-ga4';
import { useTranslation } from "react-i18next";


// Common
import Configs from './Configs';
import Routing from './common/routing';
import assessmentHR from './data/assessmentHR.json';

function App() {
  // ------------------------------------------------------------------------------------------
  // Variables
  // 👇️ with React router
  // const navigate = useNavigate();
  // const location = useLocation();

  // ------------------------------------------------------------------------------------------
  // Generic updates

  useEffect (() => {
    // Get information to populate dashboard
    getHRAssessment();
    getUniversalValues ();

  }, []);


  /**
   * Function to get questionnaire information for onboarding process
   */
   async function getHRAssessment () {  
    // Execute request through fetch API
  fetch(Configs.getHRAssessmentAPI)
    .then(response => {
      if (response.ok) {
        // Get LeadId from response if needed
        response.json().then ( body => {
          if (Configs.devEnvironment) console.log ("HR assessment received: ", body.assessment);
          sessionStorage.setItem('HRAssessment', JSON.stringify(body.assessment));
        });
      } else {
        if (Configs.devEnvironment) console.log ("HR assessment not received - No response from server", response);
        sessionStorage.setItem('HRAssessment', JSON.stringify(assessmentHR));
        throw response
      }
    })
    .catch (error => {
      console.log ("Error fetching data: ", error);
    })
  }

  /**
   * Function to receive Universal Values definitions to be used throughout the frontend
   */
     async function getUniversalValues () {  
      // Execute request through fetch API
    fetch(Configs.getUniversalValuesAPI)
      .then(response => {
        if (response.ok) {
          // Get response if needed
          response.json().then ( body => {
            if (Configs.devEnvironment) console.log ("Universal values received: ", body.values);
            sessionStorage.setItem('UniversalValues', JSON.stringify(body.values));
          });
        } else {
          if (Configs.devEnvironment) console.log ("Universal values not received - No response from server", response);
          // sessionStorage.setItem('HRAssessment', JSON.stringify(values));
          throw response
        }
      })
      .catch (error => {
        console.log ("Error fetching data: ", error);
      })
    }

  // ------------------------------------------------------------------------------------------
  // Rendering functions

    return (

      <React.Fragment>
        <div className="App">
            <BrowserRouter>
              <Routing/>
            </BrowserRouter>
        </div>
      </React.Fragment>
    );
}

export default App;
