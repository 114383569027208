import { NavLink, useLocation } from "react-router-dom";
import { BsHouseDoor } from "react-icons/bs";
import { HiChevronRight } from "react-icons/hi";

import ReactGA from 'react-ga4';


export default function Breadcrumbs() {

    const location = useLocation();
    let currentLink = "";
    let capitalizeCrumb = "";
    const crumbsArray = location.pathname.split("/");

    const crumbs = crumbsArray
        .filter((crumb) => crumb !== "")
        .map((crumb) => {
            currentLink += `/${crumb}`;
            let crumbCleaning = crumb.replace(/-/g, " ");
            capitalizeCrumb = crumbCleaning.charAt(0).toUpperCase()
                                + crumbCleaning.slice(1);

            return(
                <>
                    {(crumb === "home" || crumb === "inicio") ? 
                        <NavLink to={currentLink} > <BsHouseDoor className="w-6 h-auto shrink-0 grow-0 fill-[#107D35]"/> </NavLink>
                        :
                        <NavLink to={currentLink} className="flex flex-row items-center">
                            <HiChevronRight className="w-4 h-min shrink-0 grow-0 mr-5"/> 
                            <span className="hover:text-[#107D35] hover:underline">{capitalizeCrumb}</span>
                        </NavLink>
                    }
                </>
            )
        })

    let lastCrumb = crumbsArray[crumbsArray.length-1] ;
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: lastCrumb });

    
    return(
        <>
            {(crumbs.length > 1) &&
                <div className="flex flex-row items-center w-full max-w-screen-xl m-auto p-5 gap-5">
                    {crumbs}
                </div>
            }
        </>
        
        
    )

}