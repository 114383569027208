import { CiHeart } from "react-icons/ci";
import { VscTools } from "react-icons/vsc";
import { GiWeightLiftingUp } from "react-icons/gi";
import { SlGraph } from "react-icons/sl";
import { BiConversation } from "react-icons/bi";
import { BsArrowClockwise } from "react-icons/bs";
import { IoDiamondOutline } from "react-icons/io5";
import { IoRocketOutline } from "react-icons/io5";
import { VscTarget } from "react-icons/vsc";
import { IoStarOutline } from "react-icons/io5";
import { IoLeafOutline } from "react-icons/io5";
import { CiTrophy } from "react-icons/ci";
import { IoIceCreamOutline } from "react-icons/io5";
import { GoInbox } from "react-icons/go";
import { ImProfile } from "react-icons/im";


import heroImg from "./kincode-dashboard-mockup.gif";
import altImg from "./../../../assets/common-images/kincode-about.webp";
import evaluate2 from "./../../../assets/common-images/kincode-evaluate2.webp";
import steps from "./../../../assets/common-images/kincode-steps.webp";
import punch from "./../../../assets/common-images/kincode-punch.webp";
import stars2 from "./../../../assets/common-images/kincode-stars2.webp";


import colombon from "./../../../assets/testimonialsImages/AlexColombon.jpeg";
import qaracter from "./../../../assets/customerLogos/Qaracter_new_Logo_Color_Dark.svg";

import belisario from "./../../../assets/testimonialsImages/BelisarioMoneta.jpeg";
import inversimply from "./../../../assets/customerLogos/Logo_Inversimply-01-B.png";

// "https://cataas.com/cat"


export const hero = {
    imgSrc: heroImg,
    altImgSrc: altImg,
    imgAlt: "Kincode App home screen",
    title: "home.hero.title",
    description: "home.hero.description"
};

export const homeCardGallery = [{
    icon: <CiHeart className="w-full h-auto" />,
    title: "home.cards.card1.title",
    description: "home.cards.card1.description"
}, {
    icon: <SlGraph className="w-full h-auto" />,
    title: "home.cards.card2.title",
    description: "home.cards.card2.description"
}, {
    icon: <VscTools className="w-full h-auto" />,
    title: "home.cards.card3.title",
    description: "home.cards.card3.description"
}, {
    icon: <GiWeightLiftingUp className="w-full h-auto" />,
    title: "home.cards.card4.title",
    description: "home.cards.card4.description"
}];


export const motivacionRetencion = {
    imgSrc: evaluate2,
    imgAlt: "Evaluación con caritas sonrientes",
    subtitle: "home.imageSections.section1.subtitle",
    title: "home.imageSections.section1.title",
    description: "home.imageSections.section1.description",
    points: [{
            icon: <SlGraph className="w-full h-auto"/>,
            description: "home.imageSections.section1.points.point1"
        }, {
            icon: <BiConversation className="w-full h-auto"/>,
            description: "home.imageSections.section1.points.point2"
        }, {
            icon: <BsArrowClockwise className="w-full h-auto"/>,
            description: "home.imageSections.section1.points.point3"   
    }]
};

export const desempeno = {
    imgSrc: stars2,
    imgAlt: "Evaluación mediante estrellitas",
    subtitle: "home.imageSections.section2.subtitle",
    title: "home.imageSections.section2.title",
    description: "home.imageSections.section2.description",
    points: [{
            icon: <IoRocketOutline className="w-full h-auto"/>,
            description: "home.imageSections.section2.points.point1"
        }, {
            icon: <VscTarget className="w-full h-auto"/>,
            description: "home.imageSections.section2.points.point2"
        }, {
            icon: <IoDiamondOutline className="w-full h-auto"/>,
            description: "home.imageSections.section2.points.point3"   
    }]
};

export const desarrollo = {
    imgSrc: steps,
    imgAlt: "Proceso con steps",
    subtitle: "home.imageSections.section3.subtitle",
    title: "home.imageSections.section3.title",
    description: "home.imageSections.section3.description",
    points: [{
            icon: <IoStarOutline className="w-full h-auto"/>,
            description: "home.imageSections.section3.points.point1"
        }, {
            icon: <IoLeafOutline className="w-full h-auto"/>,
            description: "home.imageSections.section3.points.point2"
        }, {
            icon: <CiTrophy className="w-full h-auto"/>,
            description: "home.imageSections.section3.points.point3"  
    }]
};

export const essentials = {
    imgSrc: punch,
    imgAlt: "Fichaje de entrada y salida",
    subtitle: "home.imageSections.section4.subtitle",
    title: "home.imageSections.section4.title",
    description: "home.imageSections.section4.description",
    points: [{
            icon: <IoIceCreamOutline className="w-full h-auto"/>,
            description: "home.imageSections.section4.points.point1"  
        }, {
            icon: <ImProfile className="w-full h-auto"/>,
            description: "home.imageSections.section4.points.point2"  
        }, {
            icon: <GoInbox className="w-full h-auto"/>,
            description: "home.imageSections.section4.points.point3"     
    }]
};

export const greyCards = [{
    title: "home.greyCards.card1.title",
    description: "home.greyCards.card1.description"
}, {
    title: "home.greyCards.card2.title",
    description: "home.greyCards.card2.description"
}, {
    title: "home.greyCards.card3.title",
    description: "home.greyCards.card3.description"
}, {
    title: "home.greyCards.card4.title",
    description: "home.greyCards.card4.description"
}];

export const peopleCarousel = [{
    personImg: colombon,
    imgAlt: "Alexandre image",
    text: "testimonials.colombon.text",
    name: "testimonials.colombon.name",
    position: "testimonials.colombon.position",
    logo: qaracter
}, {
    personImg: belisario,
    imgAlt: "Belisario image",
    text: "testimonials.belisario.text",
    name: "testimonials.belisario.name",
    position: "testimonials.belisario.position",
    logo: inversimply
}];

export const bannerInfo = {
    title: "banner.title",
    description: "banner.description",
    btnText: "banner.cta",
    btnType: "primary"
};