import React, { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


import Layout from "../layouts/layout/Layout";
import NewHome from "../pages/home/NewHome";

const Faqs = React.lazy(() => import("./../pages/faqs/Faqs"));
const Toxic = React.lazy(() => import("./../pages/toxic-culture/ToxicCulture"));
const PricingPlans = React.lazy(() => import("./../pages/pricing-plans/PricingPlans"));
const NewContact = React.lazy(() => import("./../pages/contact/Contact"));
const AboutUs = React.lazy(() => import("./../pages/about/About"));
const Working = React.lazy(() => import("./../pages/working/Working"));

const Privacy = React.lazy(() => import("./../pages/sensitive-data/privacy-policy/Privacy"));
const Terms = React.lazy(() => import("./../pages/sensitive-data/terms/Terms"));
const SecurityTerms = React.lazy(() => import("./../pages/sensitive-data/security/Security"));
const RoadMap = React.lazy(() => import("./../pages/roadmap/Roadmap"));


const Essentials = React.lazy(() => import("../pages/functionality-pages/essentials/Essentials"));
const Motivation = React.lazy(() => import("../pages/functionality-pages/motivation/Motivation"));
const NewPerformance = React.lazy(() => import("../pages/functionality-pages/performance/Performance"));
const Development = React.lazy(() => import("../pages/functionality-pages/development/Development"));

const PerformancePerformance = React.lazy(() => import("../pages/functionality-pages/performance/pages/performance/Performance"));
const PerformanceCalibration = React.lazy(() => import("../pages/functionality-pages/performance/pages/calibration/Calibration"));
const PerformanceGoals = React.lazy(() => import("../pages/functionality-pages/performance/pages/goals/Goals"));

const DevelopmentCareers = React.lazy(() => import("../pages/functionality-pages/development/pages/career/Career"));
const DevelopmentOnboarding = React.lazy(() => import("../pages/functionality-pages/development/pages/onboarding/Onboarding"));
const DevelopmentRecruitment = React.lazy(() => import("../pages/functionality-pages/development/pages/recruitment/Recruitment"));
const DevelopmentDevelopment = React.lazy(() => import("../pages/functionality-pages/development/pages/development/Development"));

const MotivationSurvey = React.lazy(() => import("../pages/functionality-pages/motivation/pages/survey/Survey"));
const MotivationMeetings = React.lazy(() => import("../pages/functionality-pages/motivation/pages/meetings/Meetings"));
const MotivationRecognition = React.lazy(() => import("../pages/functionality-pages/motivation/pages/recognition/Recognition"));

const EssentialsProfile = React.lazy(() => import("../pages/functionality-pages/essentials/pages/profile/Profile"));
const EssentialsOrganigram = React.lazy(() => import("../pages/functionality-pages/essentials/pages/organigram/Organigram"));
const EssentialsTimeOff = React.lazy(() => import("../pages/functionality-pages/essentials/pages/time-off/TimeOff"));
const EssentialsRrhh = React.lazy(() => import("../pages/functionality-pages/essentials/pages/rrhh/Rrhh"));
const EssentialsPunch = React.lazy(() => import("../pages/functionality-pages/essentials/pages/punch/Punch"));

const NotFound = React.lazy(() => import("../pages/not-found/NotFound"));


// --------------------------------------------------------------------------------------------
function Routing () {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useMemo(() => {
        navigate(t('routes.home'));
    }, [i18n.language])
    
    // useEffect(() => {
    //     navigate(t('routes.home'));

    // }, [i18n.language, t]);

    const location = useLocation();
    const background = location.state && location.state.background;

    return (
    <React.Fragment>
    {/* Pass location to Routes so that Page remains background when ModalVersion is opened */}
        <Routes location={background || location}>

            <Route path={t("routes.layout")} element={<Layout />} >
                <Route exact path="/" element={<Navigate to={t("routes.home")} replace />} />
                    

                <Route path={t("routes.home")} element={<NewHome />} />

                
                <Route path={t("routes.faqs")} element={<Faqs />} />
                <Route path={t("routes.toxicCulture")} element={<Toxic />} />
                <Route path={t("routes.about")} element={<AboutUs />} />
                <Route path={t("routes.pricing")} element={<PricingPlans />} />
                <Route path={t("routes.contact")} element={<NewContact/>}/>
                <Route path={t("routes.working")} element={<Working/>}/>

                <Route path={t("routes.privacy")} element={<Privacy/>}/>
                <Route path={t("routes.terms")} element={<Terms/>}/>
                <Route path={t("routes.security")} element={<SecurityTerms/>}/>
                <Route path={t("routes.roadmap")} element={<RoadMap/>}/>

                <Route path={t("routes.essentials")} element={<Essentials />} />
                <Route path={t("routes.motivation")} element={<Motivation />} />
                <Route path={t("routes.performance")} element={<NewPerformance />} />
                <Route path={t("routes.development")} element={<Development />} />

                <Route path={t("routes.performancePerformance")} element={<PerformancePerformance />} />
                <Route path={t("routes.calibration")} element={<PerformanceCalibration />} />
                <Route path={t("routes.goals")} element={<PerformanceGoals />} />

                <Route path={t("routes.careers")} element={<DevelopmentCareers />} />
                <Route path={t("routes.recruitment")} element={<DevelopmentRecruitment />} />
                <Route path={t("routes.developmentDevelop")} element={<DevelopmentDevelopment />} />
                <Route path={t("routes.onboarding")} element={<DevelopmentOnboarding />} />

                <Route path={t("routes.survey")} element={<MotivationSurvey />} />
                <Route path={t("routes.meetings")} element={<MotivationMeetings />} />
                <Route path={t("routes.recognition")} element={<MotivationRecognition />} />

                <Route path={t("routes.profile")} element={<EssentialsProfile />} />
                <Route path={t("routes.organigram")} element={<EssentialsOrganigram />} />
                <Route path={t("routes.timeOff")} element={<EssentialsTimeOff />} />
                <Route path={t("routes.rrhh")} element={<EssentialsRrhh />} />
                <Route path={t("routes.punch")} element={<EssentialsPunch />} />

                <Route path="*" element={<NotFound/>}/>


            </Route>

            
            {/* Engage */}
            {/* <Route path="/product/engage/pulse" element={<Pulse />} /> */}


            {/* AI */}
            {/* <Route path="/product/ai" element={<AI />} /> */}



            {/* <Route path="/partners" element={<Partners/>}/> */}

        </Routes>
        
    {/* Open Modal if state.background is defined */}
    {background && 
        <Routes>

        </Routes>
    }            

    </React.Fragment>
    );

}

export default Routing;
