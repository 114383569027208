import Cta from "../../cta/Cta"

import { FaRegClock } from "react-icons/fa6";
import { ImProfile } from "react-icons/im";
import { IoNewspaperOutline } from "react-icons/io5";
import { IoIceCreamOutline } from "react-icons/io5";
import { FiInbox } from "react-icons/fi";
import { SlGraph } from "react-icons/sl";
import { HiOutlineUsers } from "react-icons/hi2";
import { IoMedalOutline } from "react-icons/io5";
import { CgPerformance } from "react-icons/cg";
import { LiaWeightSolid } from "react-icons/lia";
import { VscTarget } from "react-icons/vsc";
import { PiUserPlus } from "react-icons/pi";
import { IoRoseOutline } from "react-icons/io5";
import { IoLeafOutline } from "react-icons/io5";
import { IoFootstepsOutline } from "react-icons/io5";

import { FaRegStar } from "react-icons/fa6";
import { HiOutlineArrowPathRoundedSquare } from "react-icons/hi2";
import { MdOutlineWorkOutline } from "react-icons/md";
import { LuBookOpen } from "react-icons/lu";
import { CiCalendar } from "react-icons/ci";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { GoQuestion } from "react-icons/go";
import { FiTool } from "react-icons/fi";




export const products = [{
    title: "navbar.products.product1.title",
    goTo: "routes.essentials",
    elements: [
        <Cta text="navbar.products.product1.elements.element1" goTo="routes.profile" btnType="tertiary-black" iconLeft={<ImProfile className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product1.elements.element2" goTo="routes.punch" btnType="tertiary-black" iconLeft={<FaRegClock className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product1.elements.element3" goTo="routes.organigram" btnType="tertiary-black" iconLeft={<IoNewspaperOutline className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product1.elements.element4" goTo="routes.timeOff" btnType="tertiary-black" iconLeft={<IoIceCreamOutline className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product1.elements.element5" goTo="routes.rrhh" btnType="tertiary-black" iconLeft={<FiInbox className="grow-0 shrink-0"/>} />,
    ]
    },{
    title: "navbar.products.product2.title",
    goTo: "routes.motivation",
    elements: [
        <Cta text="navbar.products.product2.elements.element1" goTo="routes.survey" btnType="tertiary-black" iconLeft={<SlGraph className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product2.elements.element2" goTo="routes.meetings" btnType="tertiary-black" iconLeft={<HiOutlineUsers className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product2.elements.element3" goTo="routes.recognition" btnType="tertiary-black" iconLeft={<IoMedalOutline className="grow-0 shrink-0"/>} />
    ]
    },{
    title: "navbar.products.product3.title",
    goTo: "routes.performance",
    elements: [
        <Cta text="navbar.products.product3.elements.element1" goTo="routes.performancePerformance" btnType="tertiary-black" iconLeft={<CgPerformance className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product3.elements.element2" goTo="routes.calibration" btnType="tertiary-black" iconLeft={<LiaWeightSolid className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product3.elements.element3" goTo="routes.goals" btnType="tertiary-black" iconLeft={<VscTarget className="grow-0 shrink-0"/>} />
    ]
    }, {
    title: "navbar.products.product4.title",
    goTo: "routes.development",
    elements: [
        <Cta text="navbar.products.product4.elements.element1" goTo="routes.onboarding" btnType="tertiary-black" iconLeft={<PiUserPlus className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product4.elements.element2" goTo="routes.recruitment" btnType="tertiary-black" iconLeft={<IoRoseOutline className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product4.elements.element3" goTo="routes.developmentDevelop" btnType="tertiary-black" iconLeft={<IoLeafOutline className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.products.product4.elements.element4" goTo="routes.careers" btnType="tertiary-black" iconLeft={<IoFootstepsOutline className="grow-0 shrink-0"/>} />
    ]
}];

export const resources = [{
    title: "navbar.resources.product1.title",
    elements: [
        <Cta text="navbar.resources.product1.elements.element1" btnType="tertiary-black" iconLeft={<FaRegStar className="grow-0 shrink-0"/>}  />,
        <Cta text="navbar.resources.product1.elements.element2" btnType="tertiary-black" iconLeft={<HiOutlineArrowPathRoundedSquare className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.resources.product1.elements.element3" btnType="tertiary-black" iconLeft={<IoNewspaperOutline className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.resources.product1.elements.element4" btnType="tertiary-black" iconLeft={<MdOutlineWorkOutline className="grow-0 shrink-0"/>} />
    ]
    },{
    title: "navbar.resources.product2.title",
    elements: [
        <Cta text="navbar.resources.product2.elements.element1" btnType="tertiary-black" iconLeft={<LuBookOpen className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.resources.product2.elements.element2" btnType="tertiary-black" iconLeft={<LuBookOpen className="grow-0 shrink-0"/>} />
    ]
    },{
    title: "navbar.resources.product3.title",
    elements: [
        <Cta text="navbar.resources.product3.elements.element1" btnType="tertiary-black" iconLeft={<CiCalendar className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.resources.product3.elements.element2" btnType="tertiary-black" iconLeft={<CiCalendar className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.resources.product3.elements.element3" btnType="tertiary-black" iconLeft={<IoChatboxEllipsesOutline className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.resources.product3.elements.element4" btnType="tertiary-black" iconLeft={<IoChatboxEllipsesOutline className="grow-0 shrink-0"/>} />
    ]
    }, {
    title: "navbar.resources.product4.title",
    elements: [
        <Cta text="navbar.resources.product4.elements.element1" btnType="tertiary-black" iconLeft={<GoQuestion className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.resources.product4.elements.element2" btnType="tertiary-black" iconLeft={<GoQuestion className="grow-0 shrink-0"/>} />,
        <Cta text="navbar.resources.product4.elements.element3" btnType="tertiary-black" iconLeft={<FiTool className="grow-0 shrink-0"/>} />
    ]
}]