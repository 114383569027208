import { homeCardGallery, motivacionRetencion, desempeno, desarrollo, essentials, greyCards, hero, peopleCarousel, bannerInfo } from "./content/home";
import { useTranslation } from 'react-i18next';

import img from "./kincode-app-home.webp";


import { CardGallery } from "../../components/card/Card";
import TextImageContainer from "../../components/textImageContainer/TextImageContainer";
import Cta from "../../components/cta/Cta";
import CustomerLogos from "../../components/customerLogos/CustomerLogos";
import Carousel from "../../components/carousel/Carousel";
import Banner from "../../components/banner/Banner";


export default function NewHome() {
    const { t } = useTranslation();
    
    return(
        <>
            <main className="mb-20 md:mb-60">
                <TextImageContainer obj={hero} order="RTL" isHero={true} isAnimated={true} >
                    <div className="flex flex-col sm:flex-row-reverse justify-end gap-5">
                        <Cta text={t("buttons.free")} btnType="secondary" goTo="routes.contact" />
                        <Cta text={t("buttons.demo")} btnType="primary" goTo="routes.contact" />
                    </div>
                </TextImageContainer>

                <div className="mx-5">
                    <CustomerLogos />
                </div>
            </main>


            <div className="bg-[#F1F2F5] py-10 mb-20 md:mb-60">
                <h3 className="font-bold text-2xl pt-5 px-5">{t("home.cards.title")}</h3>
                <CardGallery arr={homeCardGallery} showBtn={false} />
            </div>


            <div className="max-w-screen-xl m-auto mb-20 md:mb-60">
                <iframe
                    className="w-full aspect-video"
                    title="Kincode - Video Sales Letter" 
                    src="https://www.loom.com/embed/a237713098f1480c815a4db9383e6e41?sid=63ccbc32-f084-4660-9cfb-9e03e8520bcc" 
                    frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    >
                </iframe>
            </div>


            <div className="mb-20 md:mb-60">
                <TextImageContainer obj={motivacionRetencion} order="RTL">
                    <div className="flex flex-col sm:flex-row">
                        <Cta text={t("buttons.information")} btnType="secondary" goTo="routes.motivation" />
                    </div>
                </TextImageContainer>

                <div className="h-16"></div>

                <TextImageContainer obj={desempeno}>
                    <div className="flex flex-col sm:flex-row">
                        <Cta text={t("buttons.information")} btnType="secondary" goTo="routes.performance" />
                    </div>
                </TextImageContainer>
            </div>

            <div className="bg-[#DFE3FF] mb-20 md:mb-60">
                <Carousel slides={peopleCarousel} />
            </div>
            

            <div className="mb-20 md:mb-60">
                <TextImageContainer className="bg-slate-400" obj={desarrollo} order="RTL">
                    <div className="flex flex-col sm:flex-row">
                        <Cta text={t("buttons.information")} btnType="secondary" goTo="routes.development" />
                    </div>
                </TextImageContainer>

                <div className="h-16"></div>


                <TextImageContainer obj={essentials}>
                    <div className="flex flex-col sm:flex-row">
                        <Cta text={t("buttons.information")} btnType="secondary" goTo="routes.essentials" />
                    </div>
                </TextImageContainer>
            </div>


            <div className="mb-16">
                <h2 className="font-bold text-4xl max-w-[45ch] px-5 m-auto">{t("home.greyCards.title")}</h2>
                <CardGallery arr={greyCards} showBtn={false} cardColor="grey" />
            </div>


            <img className="w-full max-w-screen-xl h-auto m-auto mb-20 md:mb-60 rounded shadow-lg" src={img} alt="" />

            <div className="mb-32 bg-[#BBF7D0]">
                <Banner obj={bannerInfo} />
            </div>
        </>
    )
}