import Cta from "../../cta/Cta";

import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { AiFillMediumSquare } from "react-icons/ai";

export const footerRrss = [
    {
        url: "//www.linkedin.com/company/kincode-app/",
        icon: <FaLinkedin className="w-5 h-min shrink-0 grow-0" />
    },{
        url: "//twitter.com/kincode_/",
        icon: <FaXTwitter className="w-5 h-min shrink-0 grow-0" />
    },{
        url: "//www.instagram.com/kincode.app/",
        icon: <RiInstagramFill className="w-5 h-min shrink-0 grow-0" />
    }, {
        url: "//medium.com/@kincode.app",
        icon: <AiFillMediumSquare className="w-5 h-min shrink-0 grow-0" />
    }
];

export const footerElements = [
    // {
    //     title: "footer.product.title",
    //     elements: [
    //         <Cta text="footer.product.elements.functionality" btnType="tertiary-black"  />,
    //         <Cta text="footer.product.elements.integration" btnType="tertiary-black" />,
    //         <Cta text="footer.product.elements.solution" btnType="tertiary-black" />,
    //         <Cta text="footer.product.elements.comparative" btnType="tertiary-black" />,
    //     ]
    // },{
    //     title: "footer.firm.title",
    //     elements: [
    //         <Cta text="footer.firm.elements.about" btnType="tertiary-black"  />,
    //         <Cta text="footer.firm.elements.team" btnType="tertiary-black" />,
    //         <Cta text="footer.firm.elements.news" btnType="tertiary-black" />,
    //         <Cta text="footer.firm.elements.newPartner" btnType="tertiary-black" />,
    //         <Cta text="footer.firm.elements.partners" btnType="tertiary-black" />,
    //         <Cta text="footer.firm.elements.work" btnType="tertiary-black" />
    //     ]
    // },
    // {
    //     title: "footer.resource.title",
    //     elements: [
    //         <Cta text="footer.resource.elements.academy" btnType="tertiary-black"  />,
    //         <Cta text="footer.resource.elements.events" btnType="tertiary-black" />,
    //         <Cta text="footer.resource.elements.clients" btnType="tertiary-black" />,
    //         <Cta text="footer.resource.elements.download" btnType="tertiary-black" />
    //     ]
    // },
    // {
    //     title: "footer.support.title",
    //     elements: [
    //         <Cta text="footer.support.elements.help" btnType="tertiary-black"  />,
    //         <Cta text="footer.support.elements.faq" goTo="routes.faqs" btnType="tertiary-black" />,
    //         <Cta text="footer.support.elements.support" btnType="tertiary-black" />,
    //         <Cta text="footer.support.elements.demo" btnType="tertiary-black" />,
    //         <Cta text="footer.support.elements.contact" btnType="tertiary-black" />
    //     ]
    // }
];

export const footerInformation = [{
    text: "footer.information.privacity", 
    goTo: "routes.privacy"
}, {
    text: "footer.information.service", 
    goTo: "routes.terms"
}, {
    text: "footer.information.security",
    goTo: "routes.security"
}, {
    text: "footer.information.roadmap",
    goTo: "routes.roadmap"
}, 
// {
//     text: "footer.information.cookies", 
//     // goTo: "/home/politica-de-privacidad"
// }
];