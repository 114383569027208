import { useTranslation } from "react-i18next";
import Cta from "../cta/Cta";


/**
 * Card with icon, title, description and button
 * 
 * @param {boolean} showBtn Renders the card button: true | false
 * @param {string} cardColor Select card color: "white" | "grey"
 * @param {object[]} card {icon, description, title, btnText, btnType, iconLeft, iconRight, click, goTo}
 * @returns 
 */
export default function Card({showBtn=false, card, cardColor="white"}) {
    const { t, i18n } = useTranslation();
    
    let cardStyles = "w-full p-4 pb-8 rounded overflow-hidden flex flex-col justify-between text-left"
    switch (cardColor) {
        case "white":
            cardStyles += " sm:max-w-[215px] bg-white min-h-72"
            
            break;
        case "grey":
            cardStyles += " sm:max-w-[630px] bg-[#F1F2F5] min-h-40"
            
            break;
    
        default:
            break;
    }
    return(
        <div className={cardStyles}>

            <div className="flex flex-col gap-1">
                {card.icon && 
                    <div className="w-8 h-8 mb-1">
                        {card.icon}
                    </div>
                }

                <h4 className="font-bold">{t(card.title)}</h4>
                <p>{t(card.description)}</p>
            </div>

            {showBtn && 
                <Cta text={card.btnText} click={card.click} btnType={card.btnType} iconLeft={card.iconLeft} iconRight={card.iconRight} goTo={card.goTo} />
            }

        </div> 
        
    )
};


/**
 * Gallery of cards
 * 
 * @param {array[]} arr {icon, description, title, btnText, btnType, iconLeft, iconRight, click, goTo}
 * @param {string} cardColor Select card color: "white" | "grey"
 * @param {boolean} showBtn Renders the card button
 * @returns 
 */
export function CardGallery({arr, showBtn, cardColor}) {
    return(
        <div className="w-full max-w-screen-xl m-auto py-5 px-5 md:px-0 flex flex-row flex-wrap justify-center gap-5">
            {arr.map((card, index) => {
                return(
                    <Card key={index} card={card} showBtn={showBtn} cardColor={cardColor} />
                )
            })}
            
        </div>
        
    )
}