import { useTranslation } from "react-i18next";
import Cta from "../cta/Cta";

/**
 * Banner
 * 
 * @param {object} obj {title, description, btnText, btnType}
 * @returns 
 */
export default function Banner({obj}) {
    const { t } = useTranslation();

    return(
        <div className="h-80 px-5 max-w-screen-xl m-auto flex flex-col justify-center items-center">
            <h2 className="text-4xl font-bold mb-4">{t(obj.title)}</h2>
            <p className="max-w-[54ch] mb-8" >{t(obj.description)}</p>
            <Cta text={t(obj.btnText)} btnType={obj.btnType} goTo={t("routes.contact")}></Cta>
        </div>
    )
}