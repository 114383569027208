// React
import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';


import Configs from './Configs';

// i18n imports
import {I18nextProvider} from "react-i18next";
import i18n from './i18n';

// Analytics
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

// Amplitude
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

if (!Configs.devEnvironment) {
      // Only in prod
      amplitude.init(Configs.amplitudeAPIKey);

      // Create and Install Session Replay Plugin
      const sessionReplayTracking = sessionReplayPlugin({
            sampleRate: 1, // This configuration samples 100% of all sessions - few traffic for now
      });
      amplitude.add(sessionReplayTracking);

      // GA4
      ReactGA.initialize('G-LBMR82LPE7');
      // ReactGA.initialize('G-VBXCQMLGK6');
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                  <App />
            </I18nextProvider>
      </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
