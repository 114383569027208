import { useEffect, useLayoutEffect, useState } from "react";

import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";

export default function Navbar() {

    const [size, setSize] = useState(0);

    useLayoutEffect(() => {
        function updateSize() {
        setSize(window.innerWidth);
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);
    

    const [isScreenBig, setIsScreenBig] = useState(false);
    useEffect(() => {
        setIsScreenBig(size >= 1152);
        if (isScreenBig) {
            document.body.classList.remove("overflow-hidden");
        }
    }, [isScreenBig, size]);
    

    return(
        <>
            {isScreenBig &&
                <DesktopNav />
            
            }

            {!isScreenBig &&
                <MobileNav />
            }
            
            
        </>
    )
}