import { useTranslation } from 'react-i18next';


import taxdown from "./../../assets/customerLogos/New_TaxDown_Large.png"
import inversimply from "./../../assets/customerLogos/Logo_Inversimply-01-B.png"
import namencis from "./../../assets/customerLogos/Namencis-education-logo-gray.png"
import qaracter from "./../../assets/customerLogos/Qaracter_new_Logo_Color_Dark.svg"
import leeters from "./../../assets/customerLogos/LeetersGray.png"


export default function CustomerLogos() {
    const { t } = useTranslation();

    return(
        <div className="mt-16 mx-5">
            <p className="text-2xl font-bold text-[#994F00]">{t("customerTrust.title")}</p>
            <div className="mt-8 flex flex-col sm:flex-row flex-wrap items-center justify-center lg:justify-between gap-8 max-w-screen-xl m-auto">
                <img className="w-40 h-auto" src={taxdown} alt="" />
                <img className="w-40 h-auto" src={inversimply} alt="" />
                <img className="w-40 h-auto" src={namencis} alt="" />
                <img className="w-40 h-auto" src={qaracter} alt="" />
                <img className="w-40 h-auto" src={leeters} alt="" />
            </div>
        </div>
    )
}