import Lottie from 'react-lottie';
import animationData from "./../../assets/loading/kincode.json";

export default function Loader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return(
        <div className="absolute top-0 left-0 z-50 bg-white flex flex-col justify-center items-center w-screen h-screen py-24 px-24">
            <Lottie 
                options={defaultOptions}
                height={64}
                width={64}
            />
            {/* <p className="font-bold"> Kincode </p> */}
        </div>
    )
}